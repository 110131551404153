import { render, staticRenderFns } from "./editBenefitsCorporatePackage.vue?vue&type=template&id=23b6b222&scoped=true&"
import script from "./editBenefitsCorporatePackage.vue?vue&type=script&lang=js&"
export * from "./editBenefitsCorporatePackage.vue?vue&type=script&lang=js&"
import style0 from "./editBenefitsCorporatePackage.vue?vue&type=style&index=0&id=23b6b222&prod&scoped=true&lang=css&"
import style1 from "./editBenefitsCorporatePackage.vue?vue&type=style&index=1&id=23b6b222&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b6b222",
  null
  
)

export default component.exports