<template>
    <div>
        <NavBar
        style="max-height: 50px; margin-bottom: 10px"
        :username="currentUserName"
        :pageName="pageName"
        ></NavBar>
        <v-app>
            <!-- dialog for edit benefit Start -->
            <div>
                <v-row justify="center">
                    <v-dialog v-model="editBenefitModal" persistent>
                        <v-card>
                            <div class="closeBtnDiv">
                                <v-btn icon @click="editBenefitPopupClose">
                                    <img src="@/assets/popupClose.svg" >
                                </v-btn>
                            </div>
                            <div class="benefitsRow" >
                                <div class="benefitsIndividual">
                                    <div>
                                        <p>Package Scope</p>
                                    </div>
                                    <div>
                                        <v-select
                                            solo
                                            v-model="editBenefitObject.package_type"
                                            :items="listOfPackageScope"
                                            disabled
                                            style="width: 250px;"
                                            >
                                        </v-select>
                                    </div>
                                </div>
                                <div class="benefitsIndividual">
                                    <div v-if="editBenefitObject.package_type">
                                        <p>Applicable On</p>
                                    </div>
                                    <div v-if="editBenefitObject.package_type">
                                        <v-select
                                            solo
                                            multiple
                                            v-model="editBenefitObject.applicable_list"
                                            v-if="editBenefitObject.package_type == 'Doctors'"
                                            :items="listOfDoctorsAvailable"
                                            item-text="doctor_name"
                                            item-value="_id"
                                            :rules="select_rules"
                                            return-object
                                            >
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                    ripple
                                                    @click="toggleEditDoctor"
                                                    >
                                                    <v-list-item-action>
                                                        <v-icon :color="editBenefitObject.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                                                            {{ iconEditDoc }}
                                                        </v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ selectAllDocText }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index < 5">
                                                    <span>{{ item.doctor_name }}</span>
                                                </v-chip>
                                                <span
                                                    v-if="index === 5"
                                                    class="grey--text text-caption"
                                                    >
                                                    (+{{ editBenefitObject.applicable_list.length - 5 }} others)
                                                </span>
                                            </template>
                                        </v-select>
                                        <v-select
                                            solo
                                            multiple
                                            v-else
                                            v-model="editBenefitObject.applicable_list"
                                            :items="listOfSpecializationAvailable"
                                            item-text="specialization_name"
                                            item-value="_id"
                                            :rules="select_rules"
                                            return-object
                                            >
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                    ripple
                                                    @click="toggleEditSpecialization"
                                                    >
                                                    <v-list-item-action>
                                                        <v-icon :color="editBenefitObject.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                                                            {{ iconEditSpec }}
                                                        </v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ selectAllSpecText }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index < 5">
                                                    <span>{{ item.specialization_name }}</span>
                                                </v-chip>
                                                <span
                                                    v-if="index === 5"
                                                    class="grey--text text-caption"
                                                    >
                                                    (+{{ editBenefitObject.applicable_list.length - 5 }} others)
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="benefitsIndividual">
                                    <div>
                                        <p>Promotion Type</p>
                                    </div>
                                    <div>
                                        <v-select
                                        solo
                                        v-model="editBenefitObject.promotion_type"
                                        :items="listOfPromotionTypes"
                                        disabled
                                        :rules="select_rules"
                                        >
                                        </v-select>
                                    </div>
                                </div>
                                <div class="benefitsIndividual">
                                    <div>
                                        <p v-if="editBenefitObject.promotion_type=='FLAT_OFF'">Discount Amount</p>
                                        <p v-else-if="editBenefitObject.promotion_type=='PERCENTAGE_OFF'">Discount Percentage</p>
                                        <p v-else>Discount value</p>
                                    </div>
                                    <div>
                                        <v-text-field 
                                            solo
                                            v-model="editBenefitObject.discount"
                                            :prefix = "editBenefitObject.promotion_type && editBenefitObject.promotion_type !='PERCENTAGE_OFF' ? 'MMK' : ''"
                                            :suffix = "editBenefitObject.promotion_type && editBenefitObject.promotion_type =='PERCENTAGE_OFF' ? '%' : ''"
                                            :rules="[
                                                         v => !!v || 'Discount is required',
                                                          v => !isNaN(v) || 'Must be a number',
                                                          v => !(Number(v) <= 0) || 'Must be a positive number',
                                                          v => !(editBenefitObject.promotion_type === 'PERCENTAGE_OFF' && Number(v) >=100) || 'Always less than 100%'
                                            ]"
                                            >
                                        </v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="benefitsRow2">
                                <div class="benefitsIndividual">
                                    <div>
                                        <p>Count</p>
                                    </div>
                                    <div>
                                        <v-text-field
                                            solo
                                            v-model="editBenefitObject.promotion_count"
                                            disabled
                                            style="width: 250px;"
                                            :rules="frequency_rules"
                                            >
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="benefitsIndividual">
                                    <div>
                                        <p>Minimum Spent Amount</p>
                                    </div>
                                    <div>
                                        <v-text-field :rules="min_spend_rules" solo v-model="editBenefitObject.minimum_spend" placeholder="0 if no amount" prefix="MMK">
                                        </v-text-field>
                                    </div>
                                </div>
                                <div v-if="editBenefitObject.promotion_type=='PERCENTAGE_OFF' && parseInt(editBenefitObject.discount) < 100" class="benefitsIndividual">
                                    <div>
                                        <p>Maximum Discount Amount</p>
                                    </div>
                                    <div>
                                        <v-text-field solo :rules="max_discount_rules" v-model="editBenefitObject.max_discount" placeholder="Blank if no limit" prefix="MMK">
                                        </v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="benefitsRow2">
                              <div class="FrequencyIndividual">
                                <p class="FrequencyTitle">Frequency</p>
                              </div>
                            </div>
                            <div class="benefitsRow3">
                              <div class="benefitsIndividualFrequency">
                                <div>
                                  <p>Days</p>
                                </div>
                                <div>
                                  <v-text-field
                                    solo
                                    v-model="editBenefitObject.frequency_days"
                                    style="width: 250px;"
                                    type="number"
                                    :rules="frequency_days_rules"
                                    :disabled="editBenefitObject.isFreqDisabled"
                                    >
                                  </v-text-field>
                                </div>
                              </div>
                              <div class="benefitsIndividualFrequency">
                                <div>
                                  <p>Count</p>
                                </div>
                                <div>
                                  <v-text-field
                                    solo
                                    v-model="editBenefitObject.frequency_count"
                                    type="number"
                                    :rules="frequency_count_rules"
                                    :disabled="editBenefitObject.isFreqDisabled"
                                    >
                                  </v-text-field>
                                </div>
                              </div>
                            </div>
                            <div style="display:flex; justify-content:flex-end;">
                                <v-btn style="margin-bottom: 8px; margin-right: 8px;" class="primary" @click="editBenefit(editBenefitObject)" :disabled="isEditclicked || checkDisabled(editBenefitObject)">
                                    Update
                                </v-btn>
                            </div>
                        </v-card>
                    </v-dialog>
                </v-row>
            </div>
            <!-- Edit benefit Dialog End -->
            <div>
              <v-row justify="center">
                <v-dialog v-model="verifyBenefitsPopup" persistent max-width="500">
                  <v-card>
                    <v-card-title class="createCorporateDilogTitle">
                      <div>
                        <p style="font-weight:bold;margin-top:10px" >
                          WARNING!
                        </p>
                      </div>
                    </v-card-title>
                    <div align="center">
                      Benefits once created cannot be edited. <br> Please verify them carefully before creating the benefits.
                    </div>
                    <v-card-actions style="display:flex; justify-content:space-between">
                      <div>
                        <v-btn
                          color="success"
                          width="100px"
                          @click="verifyBenefitsPopup = false"
                          >
                          Verify
                        </v-btn>
                      </div>
                      <div>
                        <v-btn
                          color="error"
                          width="100px"
                          @click="SaveAndContinue"
                          :disabled="isAddClicked"
                          >
                          Continue
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </div>
            <div style="background-color: #f5faff; height: 94.5vh !important">
            <v-row cols="12">
                <v-col cols="2">
                    <SideBar style="max-height: 50px" :pageName="pageName"></SideBar>
                </v-col>
                <v-col cols="10">
                    <div style="margin:40px" v-if="!isLoading">
                        <v-row style="margin-left: 5px" class="pageHeaderClass">
                            <div>
                                <span
                                style="font-weight: 800; font-size: 12pt;
                                text-transform: uppercase;
                                letter-spacing: 1px;">
                                <h3>Edit Package: {{ corporate_program_name }}</h3>
                                </span>
                            </div>
                            <div>
                                <v-btn style="margin-right: 20px;" class="primary" @click="addBenefitRow">Add</v-btn>
                                <v-btn  style="margin-right: 20px;" class="primary" @click="viewPackage">View</v-btn>
                            </div>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="packageBenefits"
                        class="editBenefitTable"
                        >
                        <!-- <template v-slot:item.List="{item}" >
                            <td></td>
                        </template> -->
                        <template v-slot:item.action="{item}" >
                            <v-btn :disabled ="isDeleteDisabled" class="mx-2" icon @click="editBenefitPopup(item)">
                                <img src="https://s3iconimages.mymedicine.com.mm/edit.svg" style="height: 24px; width: 24px;"/>
                            </v-btn>
                            <v-btn :disabled ="isDeleteDisabled" class="mx-2" icon @click="deleteBenefit(item)" v-show="false">
                                <img src="https://s3iconimages.mymedicine.com.mm/family_delete.svg" style="height: 24px; width: 24px;"/>
                            </v-btn>
                        </template>

                        </v-data-table>
                        <div >
                            <div v-for="(item,index2) in benefitsObject" :key=index2>
                                <v-card v-if="addMoreBenefitsFlag">
                                    <div class="closeBtnDiv" v-if="benefitsObject.length > 1">
                                        <v-btn  icon @click="deleteBenefitRow(item.package_type,item.applicable_list, item.promotion_type, item.discount, item.promotion_count, item.minimum_spend, item.max_discount)">
                                            <img src="@/assets/popupClose.svg" >
                                        </v-btn>
                                    </div>
                                    <div class="benefitsRow" >
                                        <div class="benefitsIndividual">
                                            <div>
                                                <p>Package Scope</p>
                                            </div>
                                            <div>
                                                <v-select
                                                solo
                                                v-model="item.package_type"
                                                :items="listOfPackageScope"
                                                @change="refreshIndex(index2)"
                                                style="width: 250px;"
                                                :rules="select_rules"
                                                >
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="benefitsIndividual">
                                            <div>
                                                <p>Applicable On</p>
                                            </div>
                                            <div>
                                                <v-select
                                                    solo
                                                    multiple
                                                    v-model="item.applicable_list"
                                                    v-if="item.package_type == 'Doctors'"
                                                    :items="listOfDoctorsAvailable"
                                                    item-text="doctor_name"
                                                    item-value="_id"
                                                    item-disabled="customDisabled"
                                                    :disabled="item.package_type ? false : true"
                                                    :rules="select_rules"
                                                    return-object
                                                    >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item
                                                            @click="toggleCreateDoctor(item)"
                                                            >
                                                            <v-list-item-action>
                                                                <v-icon :color="item.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                                                                    {{ iconCreateDoc(item) }}
                                                                </v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ selectAllCreateDocText(item) }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                    <template v-slot:selection="{item, index}">
                                                      <v-chip v-if="index < 5">
                                                        {{ benefitsObject[index2]['applicable_list'][index].doctor_name}}
                                                      </v-chip>
                                                      <span v-if ="index === 5" class="grey--text text-caption">
                                                        (+ {{ benefitsObject[index2]['applicable_list'].length - 5 }} others)
                                                      </span>
                                                    </template>
                                                </v-select>
                                                <v-select
                                                    solo
                                                    multiple
                                                    v-else
                                                    v-model="item.applicable_list"
                                                    :items="listOfSpecializationAvailable"
                                                    item-text="specialization_name"
                                                    item-value="_id"
                                                    item-disabled="customDisabled"
                                                    :disabled="item.package_type ? false : true"
                                                    :rules="select_rules"
                                                    return-object
                                                    >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item @click="toggleCreateSpecialization(item)">
                                                            <v-list-item-action>
                                                                <v-icon :color="item.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                                                                  {{ iconCreateSpec(item) }}
                                                                </v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                  {{ selectAllCreateSpecText(item) }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                      <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                    <template v-slot:selection="{item, index}">
                                                        <v-chip v-if="index < 5">
                                                            {{ benefitsObject[index2]['applicable_list'][index].specialization_name}}
                                                        </v-chip>
                                                        <span v-if ="index === 5" class="grey--text text-caption">
                                                            (+ {{ benefitsObject[index2]['applicable_list'].length - 5 }} others)
                                                        </span>
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="benefitsIndividual">
                                            <div>
                                                <p>Promotion Type</p>
                                            </div>
                                            <div>
                                                <v-select
                                                solo
                                                v-model="item.promotion_type"
                                                :items="listOfPromotionTypes"
                                                :rules="select_rules"

                                                >
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="benefitsIndividual">
                                            <div>
                                                <p v-if="item.promotion_type=='FLAT_OFF'">Discount Amount</p>
                                                <p v-else-if="item.promotion_type=='PERCENTAGE_OFF'">Discount Percentage</p>
                                                <p v-else>Discount value</p>
                                            </div>
                                            <div>
                                                <v-text-field
                                                solo
                                                v-model="item.discount"
                                                :prefix = "item.promotion_type && item.promotion_type !='PERCENTAGE_OFF' ? 'MMK' : ''"
                                                :suffix = "item.promotion_type && item.promotion_type =='PERCENTAGE_OFF' ? '%' : ''"
                                                :rules="[ v => !!v || 'Discount is required',
                                                          v => !isNaN(v) || 'Must be a number',
                                                          v => !(Number(v) <= 0) || 'Must be a positive number',
                                                          v => !(item.promotion_type === 'PERCENTAGE_OFF' && Number(v) >=100) || 'Always less than 100%']"
                                                >
                                                </v-text-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="benefitsRow2">
                                        <div class="benefitsIndividual">
                                            <div>
                                                <p>Count</p>
                                            </div>
                                            <div>
                                                <v-text-field
                                                    solo
                                                    v-model="item.promotion_count"
                                                    style="width: 250px;"
                                                    :rules="[v=> !!v || 'Required', v=>!(isNaN(v)) || 'Only Number allowed', v=>!(Number(v)<=0) || 'Value must be greater than zero', ]"
                                                    >
                                                </v-text-field>
                                            </div>
                                        </div>
                                        <div class="benefitsIndividual">
                                            <div>
                                                <p>Minimum Spent Amount</p>
                                            </div>
                                            <div>
                                                <v-text-field
                                                solo
                                                v-model="item.minimum_spend"
                                                placeholder="0 if no amount"
                                                prefix="MMK"
                                                :rules="min_spend_rules"
                                                >
                                                </v-text-field>
                                            </div>
                                        </div>
                                        <div v-if="item.promotion_type=='PERCENTAGE_OFF' && parseInt(item.discount) < 100" class="benefitsIndividual" style="margin-left:7%">
                                            <div>
                                                <p>Maximum Discount Amount</p>
                                            </div>
                                            <div>
                                                <v-text-field
                                                solo
                                                v-model="item.max_discount"
                                                placeholder="Blank if no limit"
                                                prefix="MMK"
                                                :rules="max_discount_rules"
                                                >
                                                </v-text-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="benefitsRow2">
                                      <div class="FrequencyIndividual">
                                        <p class="FrequencyTitle">Frequency</p>
                                      </div>
                                    </div>
                                    <div class="benefitsRow3">
                                      <div class="benefitsIndividualFrequency">
                                        <div>
                                          <p>Days</p>
                                        </div>
                                        <div>
                                          <v-text-field
                                            solo
                                            v-model="item.frequency_days"
                                            style="width: 250px;"
                                            type="number"
                                            :rules="frequency_days_rules"
                                            :disabled="item.isFreqDisabled"
                                            >
                                          </v-text-field>
                                        </div>
                                      </div>
                                      <div class="benefitsIndividualFrequency">
                                        <div>
                                          <p>Count</p>
                                        </div>
                                        <div>
                                          <v-text-field
                                            solo
                                            v-model="item.frequency_count"
                                            type="number"
                                            :rules="frequency_count_rules"
                                            :disabled="item.isFreqDisabled"
                                            >
                                          </v-text-field>
                                        </div>
                                      </div>
                                    </div>

                                    <div style="display:flex; justify-content:flex-end;">
                                        <v-btn style="margin-bottom: 8px; margin-right: 8px;" class="primary" @click="verifyFunction(benefitsObject)" :disabled=" isAddClicked || checkDisabled(item)">
                                            Save
                                        </v-btn>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          style="padding-top: 100px;"
                        ></v-progress-circular>
                    </div>
                </v-col>
            </v-row>
            </div>
        </v-app>
    </div>
</template>
<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { handleError } from '../../../utils/utils';

export default{
    name:"CreateCorporate",
    data(){
        return{
            pageName: "",
            currentUser: "",
            currentUserName: "",
            packageName:"",
            benefitsObject:[{"package_type":'',
                            "applicable_list":'',
                            "promotion_type":'',
                            "discount":'',
                            "promotion_count":'',
                            "minimum_spend":'',
                            "max_discount":''}],
            headers:[
          {
            text: 'Type',
            align: 'start',
            sortable: false,
            value: 'package_type',
          },
          { text: 'List', value: 'applicable_list',  width: "20%", class: 'listClass', cellClass:'listClass'},
          { text: 'Promotion Type', value: 'promotion_type' },
          { text: 'Discount Amount', value: 'discount' },
          { text: 'Count', value: 'promotion_count' },
          { text: 'Min Spent Amount', value: 'minimum_spend' },
          { text: 'Max Discount', value: 'max_discount' },
          { text: 'Frequency Days', value: 'frequency_days' },
          { text: 'Frequency Count', value: 'frequency_count' },
          { text: 'Action', value: 'action' },
        ],
            listOfPackageScope:["Specializations","Doctors"],
            listOfDoctorsAvailable:null,
            listOfSpecializationAvailable:null,
            listOfPromotionTypes:['PERCENTAGE_OFF', 'FLAT_OFF'],
            created_by:"",
            packageID:"",
            packageBenefits:[],
            addMoreBenefitsFlag:false,
            customDisabled: null,
            isDeleteDisabled: false,
            corporate_program_name: "",
            isLoading: true,
            isSaveButtonClicked: false,
            editBenefitModal : false,
            editBenefitObject : {},
            isAddClicked: false,
            isEditclicked: false,
            frequency_days_rules: [(v) => v > 0 || "Days cannot be negative"],
            frequency_count_rules: [(v) => v > 0 || "Count cannot be negative"],
            verifyBenefitsPopup: false,
            currentItem: null,
            continueDisable: false,
            discount_rules: [
                v => !!v || 'Max discount is required',
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <= 0) || 'Must be a positive number',
                v => !(this.selected_promotion_type === 'PERCENTAGE_OFF' && Number(v) >=100) || 'Always less than 100%'
              ],
            min_spend_rules: [
                v => !!v || 'Minimum spend is required',
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <0) || 'Must be greater than or equal to zero',
              ],
            max_discount_rules: [
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <0) || 'Must be greater than or equal to zero',
            ],
            frequency_rules: [
                v => !!v || 'Required',
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <=0) || 'Must be greater than zero',
            ],
            select_rules: [
                v => !(v.length <= 0) || 'Select atleast one value'
            ],
            count_rules: [
                v => !!v || "Required"
            ]
        }
    },
    components: {
      NavBar,
      SideBar,
    },
    mounted(){
        this.pageName = "EditBenefitsCorparatePackage";
        this.currentUser = this.$cookies.get("token");

        if (!this.currentUser) {
        this.$router.push({
            name: "AdminLogin",
        });
        } else {
            var params = this.$route.params.package_id;
            this.packageID = params
            var getAdminBody ={
                token : this.currentUser,
                typeOfUser : "ADMIN",
                adminType : "ADMIN"
                }
                axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAdminBody)
                .then((getAdminResponse)=>{
                this.created_by = getAdminResponse.data.data._id
                var readCorporatePackageByIdBody ={
                    corporatePackageId:this.packageID,
                    token : this.currentUser,
                    typeOfUser : "ADMIN",
                }
                axios.post(process.env.VUE_APP_BACKEND_URL+"/readCorporatePackageById",readCorporatePackageByIdBody)
                .then((readCorporatePackageByIdResponse)=>{
                    let listOfSpecDoc = readCorporatePackageByIdResponse.data.data.benefits
                    this.corporate_program_name = readCorporatePackageByIdResponse.data.data.corporate_program_name;
                    listOfSpecDoc.forEach(function(item,index){
                        item.applicable_list = item.applicable_list_str.join(", ");
                        if (item.frequency)
                        {
                            item.frequency_days = item.frequency ? item.frequency.number_of_days : '-';
                            item.frequency_count = item.frequency ?  item.frequency.booking_count : '-';
                        }
                    })
                    this.packageBenefits = listOfSpecDoc;
                    this.isLoading = false;
                })
                .catch((readCorporatePackageByIdError)=>{
                    this.isLoading = false;
                    handleError(readCorporatePackageByIdError, this.$router, 'readCorporatePackageByIdError', 'AdminLogin', 'token');
                })
            })
            .catch((getAdminError)=>{

                if (getAdminError.response.status == 401) {
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$router.push({
                        name: "AdminLogin",
                    });
                } else {
                    this.isLoading = false;
                    window.alert("Something went wrong while getting Admin data");
                }
            })
        }
    },
    computed: {
        //edit Spec Popup
        selectEditAllSpecDisable () {
            return this.editBenefitObject.applicable_list.length === this.listOfSpecializationAvailable.length;
        },
        selectEditParticularSpecialization () {
            return this.editBenefitObject.applicable_list.length > 0;
        },
        iconEditSpec () {
            if (this.selectEditAllSpecDisable) return 'mdi-close-box';
            if (this.selectEditParticularSpecialization) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        selectAllSpecText() {
            return this.editBenefitObject.applicable_list.length === this.listOfSpecializationAvailable.length ? 'Unselect All' : 'Select All'
        },
        //edit Doc Popup
        selectEditAllDocDisable () {
            return this.editBenefitObject.applicable_list.length === this.listOfDoctorsAvailable.length;
        },
        selectEditParticularDoctor () {
            return this.editBenefitObject.applicable_list.length > 0;
        },
        iconEditDoc () {
            if (this.selectEditAllDocDisable) return 'mdi-close-box';
            if (this.selectEditParticularDoctor) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        selectAllDocText() {
            return this.editBenefitObject.applicable_list.length === this.listOfDoctorsAvailable.length ? 'Unselect All' : 'Select All'
        },
    },
    methods:{
        //Create Spec module
        selectAllCreateSpecText(item) {
            return item.applicable_list.length === this.listOfSpecializationAvailable.length ? 'Unselect All' : 'Select All';
        },
        selectCreateAllSpecDisable (item) {
            return item.applicable_list.length === this.listOfSpecializationAvailable.length;
        },
        selectCreateParticularSpecialization (item) {
            return item.applicable_list.length > 0;
        },
        iconCreateSpec (item) {
              if (this.selectCreateAllSpecDisable(item)) return 'mdi-close-box';
              if (this.selectCreateParticularSpecialization(item)) return 'mdi-minus-box';
              return 'mdi-checkbox-blank-outline';
        },
        toggleEditSpecialization() {
            if (this.selectEditAllSpecDisable) {
                this.editBenefitObject.applicable_list = [];
            } else {
                this.editBenefitObject.applicable_list = this.listOfSpecializationAvailable.slice();
            }
        },
        toggleCreateSpecialization(item) {
            if (this.selectCreateAllSpecDisable(item)) {
                item.applicable_list = [];
            } else {
                item.applicable_list = this.listOfSpecializationAvailable.slice();
            }
        },
        //Create Docu module
        selectAllCreateDocText(item) {
            return item.applicable_list.length === this.listOfDoctorsAvailable.length ? 'Unselect All' : 'Select All';
        },
        selectCreateAllDocDisable (item) {
            return item.applicable_list.length === this.listOfDoctorsAvailable.length;
        },
        selectCreateParticularDoctor (item) {
            return item.applicable_list.length > 0;
        },
        iconCreateDoc (item) {
            if (this.selectCreateAllDocDisable(item)) return 'mdi-close-box';
            if (this.selectCreateParticularDoctor(item)) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        toggleEditDoctor() {
            if (this.selectEditAllDocDisable) {
                this.editBenefitObject.applicable_list = [];
            } else {
                this.editBenefitObject.applicable_list = this.listOfDoctorsAvailable.slice();
            }
        },
        toggleCreateDoctor(item) {
            if (this.selectCreateAllDocDisable(item)) {
                item.applicable_list = [];
            } else {
                item.applicable_list = this.listOfDoctorsAvailable.slice();
            }
        },
        SaveAndContinue()
        {
            this.saveBenefit(this.currentItem);
        },
        verifyFunction(addBenefit)
        {
            this.currentItem = addBenefit;
            this.verifyBenefitsPopup = true;

        },
        checkDisabled(editBenefitObject)
        {
            return !(editBenefitObject.promotion_type && ( editBenefitObject.promotion_count && parseInt(editBenefitObject.promotion_count) > 0 ) && (editBenefitObject.applicable_list && editBenefitObject.applicable_list.length > 0 ) && editBenefitObject.package_type && (editBenefitObject.discount && parseInt(editBenefitObject.discount) > 0) && ( (editBenefitObject.promotion_type == 'FLAT_OFF' && parseInt(editBenefitObject.minimum_spend) >= 0 ) || editBenefitObject.promotion_type == 'PERCENTAGE_OFF' && parseInt(editBenefitObject.discount) <= 100 && parseInt(editBenefitObject.minimum_spend) >= 0 && ( ( !editBenefitObject.max_discount && parseInt(editBenefitObject.max_discount) != 0 ) || parseInt(editBenefitObject.max_discount) > 0 ) ) && ( (editBenefitObject.frequency_count && editBenefitObject.frequency_days && editBenefitObject.frequency_count > 0 && editBenefitObject.frequency_days > 0 && editBenefitObject.frequency_count <= parseInt(editBenefitObject.promotion_count) ) || (!editBenefitObject.frequency_count && !editBenefitObject.frequency_days) ) );
        },
        viewPackage()
        {
            this.$router.push({
                path: "/admin/ViewCorporatePackage/"+this.packageID
            });
        },
        refreshIndex(index)
        {
            this.benefitsObject[index].applicable_list = [];

            //comment this if u want to disable across benefits
            // this.listOfSpecializationAvailable.map((x) => {
            //     x.customDisabled = false
            // });
            // this.listOfDoctorsAvailable.map((x) =>{
            //     x.customDisabled = false
            // });
        },
        editBenefit(benefitObject){
            this.isEditclicked = true;
            benefitObject.max_discount = (benefitObject.promotion_type == 'PERCENTAGE_OFF' && benefitObject.discount == '100') ? '' : ( benefitObject.max_discount ? benefitObject.max_discount : '');
            benefitObject.applicable_list = benefitObject.applicable_list.map((x) => {
                return x._id;
            });
            let editBenefitOfCorporatePackageBody = {
                corporatePackageId : this.packageID,
                benefit : JSON.stringify(benefitObject),
                updatedBy : this.created_by,
                token : this.currentUser,
                typeOfUser : "ADMIN",
            };
            if (!benefitObject.frequency_count || (benefitObject.frequency_count <= parseInt(benefitObject.promotion_count)) )
            {
                axios.post(process.env.VUE_APP_BACKEND_URL + "/editBenefitOfCorporatePackage", editBenefitOfCorporatePackageBody)
                .then((editBenefitOfCorporatePackageResponse) => {
                    // console.log("editBenefitOfCorporatePackageResponse : ", editBenefitOfCorporatePackageResponse);
                    window.alert("Benefit edited successfully!");
                    this.editBenefitModal = false;
                    window.location.reload();
                })
                .catch((editBenefitOfCorporatePackageError) => {
                    this.isEditclicked = false;
                    handleError(editBenefitOfCorporatePackageError, this.$router, 'editBenefitOfCorporatePackageError', 'AdminLogin', 'token');
                });
            } else {
                window.alert('Frequency count cannot be greater than issue count');
                this.isEditclicked = false;
            }
        },
        editBenefitPopup(item){
            this.editBenefitObject = {
                id : item.id,
                package_type : item.package_type,
                applicable_list : [],
                promotion_type : item.promotion_type,
                discount : item.discount,
                max_discount : item.max_discount ? item.max_discount : '',
                promotion_count : item.promotion_count,
                minimum_spend : item.minimum_spend,
                frequency_days: item.frequency_days ? item.frequency_days : null,
                frequency_count: item.frequency_count ? item.frequency_count : null,
                isFreqDisabled: ( item.frequency_count && item.frequency_days ) ? true : false,
                status : item.status
            };
            let editBenefitApplicableListBody = {
                corporatePackageId : this.packageID,
                benefitId : item.id,
                token : this.currentUser,
                typeOfUser : "ADMIN",
            };


            axios.post(process.env.VUE_APP_BACKEND_URL + "/editBenefitApplicableList", editBenefitApplicableListBody)
            .then((editBenefitApplicableListResponse) => {
                // console.log("editBenefitApplicableListResponse : ", editBenefitApplicableListResponse);
                let applicableSpecializations = editBenefitApplicableListResponse.data.applicable_specializations;
                let applicableDoctors = editBenefitApplicableListResponse.data.applicable_doctors;

                this.listOfSpecializationAvailable = applicableSpecializations.map((ele) => {
                    return {
                        _id : ele._id,
                        specialization_name : ele.specialization_name,
                        customDisabled : false
                    }
                });
                this.listOfDoctorsAvailable = applicableDoctors.map((ele) => {
                    return {
                        _id : ele._id,
                        doctor_name : ele.doctor_name,
                        customDisabled : false
                    }
                });
                this.editBenefitModal = true;
            })
            .catch((editBenefitApplicableListError) => {
                handleError(editBenefitApplicableListError, this.$router, 'editBenefitApplicableListError', 'AdminLogin', 'token');
            });
        },
        editBenefitPopupClose(){
            this.editBenefitObject.applicable_list = [];

            this.editBenefitModal = false;
        },
        saveBenefit(benefitsObject){
            benefitsObject[0].max_discount = (benefitsObject[0].promotion_type == 'PERCENTAGE_OFF' && benefitsObject[0].discount == '100') ? '' : ( benefitsObject[0].max_discount ? benefitsObject[0].max_discount : '')
            this.isAddClicked = true;
            benefitsObject[0].applist = benefitsObject[0].applicable_list.map((x) => {
                return x._id;
            });
            let { applist, ...rest } = benefitsObject[0];
            let new_var = rest;
            new_var.applicable_list = applist;
            let addBenefitToCorporatePackageBody = {
                corporatePackageId : this.packageID,
                benefit : JSON.stringify(new_var),
                updatedBy : this.created_by,
                token : this.currentUser,
                typeOfUser : "ADMIN",
            };
            if(!new_var.frequency_count || (new_var.frequency_count <= parseInt(new_var.promotion_count) ) )
            {
                axios.post(process.env.VUE_APP_BACKEND_URL + "/addBenefitToCorporatePackage", addBenefitToCorporatePackageBody)
                .then((addBenefitToCorporatePackageResponse) => {
                    // console.log("addBenefitToCorporatePackageResponse : ", addBenefitToCorporatePackageResponse);
                    window.alert("Benefit added successfully!");
                    window.location.reload();
                })
                .catch((addBenefitToCorporatePackageError) => {
                    this.isAddClicked = false;
                    this.verifyBenefitsPopup = false;
                    handleError(addBenefitToCorporatePackageError, this.$router, 'addBenefitToCorporatePackageError', 'AdminLogin', 'token')
                });
            } else {
                window.alert('Frequency count cannot be greater than issue count');
                this.isAddClicked = false;
            }

        },
        addBenefitRow(){
            let getValidApplicableListBody = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                corporatePackageId : this.packageID
            };
            axios.post(process.env.VUE_APP_BACKEND_URL + "/getValidApplicableList", getValidApplicableListBody)
            .then((getValidApplicableListResponse) => {
                let applicableDoctors = getValidApplicableListResponse.data.applicable_doctors;
                let applicableSpecializations = getValidApplicableListResponse.data.applicable_specializations;

                this.listOfSpecializationAvailable = applicableSpecializations;
                this.listOfDoctorsAvailable = applicableDoctors;

                if(this.addMoreBenefitsFlag){
                    this.benefitsObject.push(
                        {
                            "package_type" : '',
                            "applicable_list" : '',
                            "promotion_type" : '',
                            "discount" : '',
                            "promotion_count" : '',
                            "minimum_spend" : '',
                            "max_discount" : ''
                        }
                    );
                }
                this.addMoreBenefitsFlag = true;
            })
            .catch((getValidApplicableListError) => {
                handleError(getValidApplicableListError, this.$router, 'getValidApplicableListError', 'AdminLogin', 'token');                
            });
        },
        deleteBenefitRow(package_type, applicable_list, promotion_type, discount, promotion_count, minimum_spend, max_discount ) {
            this.benefitsObject.forEach(function (item, index, object) {
            if (item.package_type === package_type && item.applicable_list === applicable_list && item.promotion_type === promotion_type && item.discount === discount && item.promotion_count === promotion_count && item.minimum_spend === minimum_spend && item.max_discount === max_discount)
            {
                object.splice(index, 1);
            }
            });
        },
        deleteBenefit(item){
            this.isDeleteDisabled = true;
            var removeBenefitFromCorporatePackageBody = {
                corporatePackageId:this.packageID,
                benefitId:item.id,
                updatedBy: this.created_by,
                token : this.currentUser,
                typeOfUser : "ADMIN",
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/removeBenefitFromCorporatePackage",removeBenefitFromCorporatePackageBody)
            .then((removeBenefitFromCorporatePackageResponse)=>{
                window.alert("Benefit removed");
                this.$router.go();
            })
            .catch((removeBenefitFromCorporatePackageError)=>{
                console.log("removeBenefitFromCorporatePackageError",removeBenefitFromCorporatePackageError);
                window.alert("Something went wrong while removing benefit");
                this.isDeleteDisabled = false;
            });
        },
        // uncomment the below ones if you want to disable once they select a spec or doctor
        // disableSelectedSpecialization(selectedSpecialization){
        //     selectedSpecialization.forEach(element => {
        //         let elementToBeDisabled = this.listOfSpecializationAvailable.findIndex(obj => obj._id == element)
        //         this.listOfSpecializationAvailable[elementToBeDisabled].customDisabled = true
        //     });
        // },
        // disableSelectedDoctor(selectedDoctor){
        //     selectedDoctor.forEach(element => {
        //         let elementToBeDisabled = this.listOfDoctorsAvailable.findIndex(obj => obj._id == element);
        //         this.listOfDoctorsAvailable[elementToBeDisabled].customDisabled = true
        //     });
        // },
    }
}

</script>
<style scoped>
.FrequencyIndividual{
    margin: 0px 18px;
}
.FrequencyTitle{
    font-weight: 700;
    line-height: 19px;
}
.benefitsRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px 18px;
    margin-bottom: 0px;
}
.benefitsRow2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin:  0px 18px;
}
.benefitsRow3{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px 18px;
}
.benefitsIndividual{
    display: flex;
    flex-direction: column;
    margin: 18px;
    text-align: left;
}
.benefitsIndividualFrequency{
    display: flex;
    flex-direction: column;
    margin: 8px 18px;
    text-align: left;
}
.vbtnClass{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;
}
.closeBtnDiv{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 18px;
    margin-top: 10px;
}
.editBenefitTable{
    margin-top:24px
}
.pageHeaderClass{
    display: flex;
    justify-content: space-between;
}
</style>
<style>
/* .ListClass .v-data-table td{
    width: 100px;
} */
.listClass{
    max-width: 100px;
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap
}
.editBenefitTable .v-data-table-header th{
    background: #1467bf !important;
    color: white !important;
    font-size: 8pt !important;
    font-weight: 500 !important;
}
.editBenefitTable tbody td{
    font-size: 8pt !important;
    font-weight: 700 !important;
}
</style>
